import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import './BrokerView.css';
import Loader from '../../Components/Loader/loader';
import { useSelector } from 'react-redux';
import {
	defaultcenter,
	mapOptions,
	DistanceList,
} from '../CarbonCredits/constants';
import GoogleMapsComp from '../../Components/GoogleMapsComponent/GoogleMapsComp';
import {
	InputAutoAddress,
	InputBoxWithDropdown,
} from '../../Components/InputFields/InputField';
import MultipleSelect from '../../Components/InputTag/MultipleSelect';

import {
	GetCarbonCreditsFiltersDropdowns,
} from '../../api/carbonCredits/endpoints';
import { BrokerViewData } from '../../api/broker_view/endpoints';
import CreditsTable from './CreditsTable';
const libraries = ['places'];


const BrokerView = () => {
	const [isLoading, setIsLoading] = useState(false);
	const { selectedProject } = useSelector((state) => state.project);

	// FILTERS
	const initialFilterData = {
		project_id: null,
		latitude: null,
		longitude: null,
		distance: null,
		milk_sold_to: null,
		farm_state: null,
	};	  
	const [filterDataForAPI, setFilterDataForAPI] = useState(initialFilterData);

	
	// State vars to store the values of selected filters
	const [milkSoldToValue, setMilkSoldToValue] = useState([]);
	const [farmStateValue, setFarmStateValue] = useState([]);
	// Default filter details
	const initialFilterDetails = [
		{
			key: 0,
			label: 'Address',
			type: 'text',
			value: '',
			id: 'address',
			name: 'address',
			error: false,
			notRequired: true,
		},
		{
			key: 1,
			label: 'Distance',
			type: 'dropdown',
			value: '100 miles',
			error: false,
			list: DistanceList,
			id: 'distance',
			disabled: false,
			notRequired: true,
		},
		{
			key: 2,
			label: 'Milk Sold to',
			type: 'dropdown-tag',
			values: milkSoldToValue,
			setValue: setMilkSoldToValue,
			error: false,
			list: [],
			id: 'milk_sold_to',
			disabled: false,
			notRequired: true,
		},
		{
			key: 3,
			label: 'Farm State/Province',
			type: 'dropdown-tag',
			error: false,
			values: farmStateValue,
			setValue: setFarmStateValue,
			list: [],
			id: 'farm_state',
			disabled: false,
			notRequired: true,
		},
	];
	const [filterDetails, setFilterDetails] = useState(initialFilterDetails);
	// Map Stuff
	const inputRef = useRef();
	const { isLoaded: mapLoader } = useJsApiLoader({
		libraries,
		options: mapOptions,
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
	});

	const [center, setCenter] = useState(defaultcenter('')); // Center of the map
	const [initialMP, setInitialMP] = useState(); // Initial Marker Point
	const [updated, setUpdated] = useState(false); // Tracks if the map has been updated based on filters, etc
	console.log('center:', center);
	// Credits Table Data
	const [creditsTableData, setCreditsTableData] = useState([]);

	const handleAddressChange = useCallback(() => {
		const [place] = inputRef.current.getPlaces();
		if (place) {
			setFilterDetails(filterDetails.map(dets => dets.label === 'Address' ? { ...dets, value: place.name } : dets));
			const { lat, lng } = place.geometry.location;
			setCenter({ lat: lat(), lng: lng() });
			setFilterDataForAPI(prevData => ({
				...prevData,
				latitude: lat(),
				longitude: lng(),
				distance: parseInt(filterDetails[1].value),
			}));
			setUpdated(!updated);
		}
	}, [filterDetails,updated]);



	const handleSelect = useCallback((label, value) => {
		setFilterDetails(filterDetails.map(data => {
			if (data.label === label) {
				data.id === 'distance'
					? setFilterDataForAPI(prevData => ({ ...prevData, [data.id]: parseInt(value) }))
					: setFilterDataForAPI(prevData => ({ ...prevData, [data.id]: value }));
				return { ...data, isError: false, value };
			}
			return data;
		}));
		setUpdated(!updated);
	}, [filterDetails, updated]);

	const handleMultiSelect = useCallback((data, label) => {
		setFilterDetails(filterDetails.map(details => {
			if (details.label === label) {
				setFilterDataForAPI(prevData => ({ ...prevData, [details.id]: data }));
				return { ...details, values: data };
			}
			return details;
		}));
	}, [filterDetails]);


	const fetchData = useCallback(async () => {
		setIsLoading(true);
		try {
			const resDropdownOptions = await GetCarbonCreditsFiltersDropdowns(selectedProject.id);
			const filteredData = Object.fromEntries(
				Object.entries(filterDataForAPI).filter(([_, value]) => value !== null)
			);
			filteredData.project_id = selectedProject.id;

			const resCarbonCredits = await BrokerViewData(filteredData);

			if (resDropdownOptions.status === 200 && resCarbonCredits.status === 200) {
				const { milk_sold_to_options: resMilk, farm_state_options: resState } = resDropdownOptions.data;
				const milkData = resMilk.map((value, index) => ({ id: index + 1, name: value }));
				const stateData = resState.map((value, index) => ({ id: index + 1, name: value }));

				const updated = filterDetails.map((details) => {
					switch (details.id) {
						case 'milk_sold_to': // Corrected to match the id in filterDetails
							return { ...details, list: milkData, isError: false };
						case 'farm_state': // Corrected to match the id in filterDetails
							return { ...details, list: stateData, isError: false };
						default:
							return details;
					}
				});
				setFilterDetails(updated);
				setInitialMP(resCarbonCredits.data.farm_locations)
				setCreditsTableData(resCarbonCredits.data.credits_table_data);
				setIsLoading(false);

			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}, [selectedProject, filterDataForAPI]);

	// Initial Pull on Loading...
	useEffect(() => {
		if (selectedProject !== null) {
			fetchData();
			setCenter(defaultcenter(localStorage.getItem('selectedProjectCountry')));
		}
	}, [selectedProject, filterDataForAPI]);

	const handleClear = () => {
		setCenter(defaultcenter(localStorage.getItem('selectedProjectCountry')));
		setFilterDetails(initialFilterDetails);
		// setCardDetails(initialCardDetails);
		setFilterDataForAPI(initialFilterData);
	};

	const handleValueChange = useCallback((label, value) => {
		setFilterDetails(filterDetails.map(dets => dets.label === label ? { ...dets, value } : dets));
	}, [filterDetails]);

	return isLoading ? <Loader /> : (
		<div className="broker-view-container">
			<div className="filters-and-map">
				<div className="filters">
					<div className="address-fields">
						{filterDetails.slice(0, 2).map((details, index) => (
							details.type === 'text' ? (
								<InputAutoAddress
									key={details.key}
									label={details.label}
									value={details.value}
									type={details.type}
									isError={details.error}
									handlePlaceChanged={handleAddressChange}
									notRequired={details.notRequired}
									handleValueChange={handleValueChange}
									isLoaded={mapLoader}
									inputRef={inputRef}
								/>
							) : (
								<InputBoxWithDropdown
									key={details.key}
									width={'40%'}
									list={details.list}
									label={details.label}
									value={details.value}
									isError={details.error}
									disable={details.disabled}
									actualIndex={details.key}
									outerindex={details.key}
									notRequired={details.notRequired}
									onSelect={handleSelect}
								/>
							)
						))}
					</div>
					<div className='other-fields'>
						{filterDetails.slice(2).map((details, index) => (
							<div key={index} className='dropdown-fields'>
								<div className="label">{details.label}</div>
								<MultipleSelect
									checked={details.values}
									handleMultiSelect={handleMultiSelect}
									allOptionsEmail={details.list || []}
									isError={details.error}
									showAddButton={false}
									isFilter={true}
									label={details.label}
									setUpdated={setUpdated}
									updated={updated}
								/>
							</div>
						))}
					</div>
					<div className="clear-button" onClick={handleClear}>Clear</div>
				</div>
				<div className="geo-map">
					<GoogleMapsComp
						center={center}
						markerPoint={center}
						isLoaded={mapLoader}
						mapOptions={mapOptions}
						initialMP={initialMP}
						containerStyle={{ width: '100%', height: '100%' }}
					/>
				</div>
			</div>
			<br />
			<div className='credits-table'>
				<CreditsTable 
					isLoading={isLoading}
					creditsTableData={creditsTableData}
				/>
			</div>
		</div>
	);
};

export default BrokerView;
