import axiosAuthMiddleware from '../middleware/middleware';

export const BrokerViewData = async(data) =>{
    const url = `/broker_view_data`
    const res = axiosAuthMiddleware.request({
        url: url,
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain',
        },
        data:data
    });
    return await res;
}

export const BrokerViewSavePreAssigned = async(data) =>{
    const url = `/broker_view_save_preassigned`
    const res = axiosAuthMiddleware.request({
        url: url,
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain',
        },
        data:data
    });
    return await res;
}