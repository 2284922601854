import React from 'react';
import './App.css';
import './index.css';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from '../src/Pages/Auth/Login/Login';
import ForgotPassword from '../src/Pages/Auth/ForgotPassword/ForgotPassword';
import CommonLayout from '../src/Components/CommonLayout/CommonLayout';
import CreateFarm from '../src/Pages/Farm/CreateFarm';
import FacilatorPortfolio from '../src/Pages/FacilatorPortfolio/FacilatorPortfolio';
import ContactUs from '../src/Pages/ContactUs/ContactUs';
import FarmForm from './Pages/FarmForm/FarmForm';
import ProtectedRoutes from './ProtectedRoute/ProtectRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Verifyfacilitator from './Pages/FarmForm/Verifyfacilitator';
import PrivacyPolicy from './Pages/Terms/PrivacyPolicy';
import TermsAndCondition from './Pages/Terms/TermsAndCondition';
import LogRocket from 'logrocket';
import Cookies from 'js-cookie';
import CarbonCredits from './Pages/CarbonCredits/CarbonCredits';
import DownloadData from './Pages/DownloadData/DownloadData';
import BrokerView from './Pages/BrokerView/BrokerView';
import FAQs from './Pages/FAQs/FAQs';

LogRocket.init('zpjmca/carboncredify');
const user_data = Cookies.get('user_data');
if (user_data) {
  const user_data_parsed = JSON.parse(user_data);
  LogRocket.identify(user_data_parsed.id, {
    name: user_data_parsed.first_name + ' ' + user_data_parsed.last_name,
    email: user_data_parsed.email,
    role: user_data_parsed.role,
  });
}

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route
          path="/"
          element={
            <ProtectedRoutes redirectTo="/login">
              <CommonLayout />
            </ProtectedRoutes>
          }
        >
          <Route path="new-farm" element={<CreateFarm />} />
          <Route path="/" element={<Navigate to="/new-farm" replace />} />
          <Route path="farm-portfolio" element={<FacilatorPortfolio />} />
          <Route path="carboncredits" element={<CarbonCredits />} />
          <Route path="download-data" element={<DownloadData />} />
          <Route path="broker-view" element={<BrokerView />} />
          <Route path="contact-us" element={<ContactUs />} />
        </Route>
        <Route path="/farm-form/:id" element={<FarmForm />} />
        <Route path="/verify" element={<Verifyfacilitator />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-services" element={<TermsAndCondition />} />
        <Route path="/faqs/:category" element={<FAQs />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
