import React, { useState } from 'react';
import './DownloadData.css';
import { DownloadAuditorData} from '../../api/downloadData/endpoints';
import Loader from '../../Components/Loader/loader';
import { Button } from '../../Components/Button/Button';

const DownloadData = () => {
	console.log('DownloadData');
	const [isLoading, setIsLoading] = useState(false);

	return isLoading ? (<Loader />) : (
		<div className="download-container">
			<Button
				text="Download Data for Auditing"
				maxWidth="260px"
				handleClick={() => {
					console.log('DownloadAuditorData');
					setIsLoading(true);
					DownloadAuditorData({project_id: localStorage.getItem('selectedProject')}).then((res) => {
						setIsLoading(false);
						console.log('DownloadAuditorData', res);
					})
				}}
				isLoading={isLoading}
			/>
			<br />
			<p>Once you click on the button above, we'll collect all the relevant data and files and email you the file. If you do not receive the email within 15 minutes, please reach out to <a href="mailto:support@carboncredify.ai">support@carboncredify.ai</a>.</p>
			</div>
	);
};

export default DownloadData;
