export const defaultcenter = (country) => (
  country === 'Canada' ? {
  lat: 54.5260,
  lng: -105.2551,
} : {
  lat: 37.0902,
  lng: -95.7129,
});

export const mapStyles = [
  {
    featureType: "all",
    elementType: "labels.text",
    stylers: [
      { visibility: "off" }, // Hide all text labels by default
    ],
  },
  {
    featureType: "administrative.country",
    elementType: "labels.text",
    stylers: [
      { visibility: "on" }, // Show main country labels
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        color: "#E4E4E4", // Set land area color to a specific color
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff", // Set water color to white
      },
    ],
  },
];

export const mapOptions = {
  zoom:3,
  styles: mapStyles,
  mapTypeControl: false,
  streetViewControl: false,
};

//initial list
export const DistanceList = [
  {
    id: 2,
    name: "10 miles",
  },
  {
    id: 3,
    name: "50 miles",
  },
  {
    id: 4,
    name: "100 miles",
  },
  {
    id: 5,
    name: "200 miles",
  },
  {
    id: 6,
    name: "500 miles",
  },
  {
    id: 7,
    name: "1000 miles",
  },
  {
    id: 8,
    name: "2000 miles",
  },
];
