import React, { useRef, useState } from 'react';
import PassVisibleIcon from '../../images/Auth/EyeIcon.svg';
import PassNotVisibleIcon from '../../images/Auth/EyeIconNotVisible.svg';
import './inputField.css';
import DropdownIcon from '../../images/Sidebar/Vector (1).svg';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import Loader from '../Loader/loader';
import { StandaloneSearchBox } from '@react-google-maps/api';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export const InputField = ({
	text,
	type,
	placeholder,
	value,
	name,
	handleOnChange,
	fontSize,
	disable,
	onEnterFunction,
}) => {
	const [passwordVisible, setPasswordVisible] = useState(false);
	return (
		<div className="InputField">
				<input
				name={name}
				type={
					passwordVisible &&
					(name === 'password' ||
						name === 'newPassword' ||
						name === 'confirmPassword' ||
						name === 'old_password' ||
						name === 'new_password' ||
						name === 'confirm_password')
						? 'text'
						: type
				}
				style={{ fontSize: fontSize ? fontSize : '' }}
				placeholder={placeholder}
				onChange={(e) => handleOnChange(e)}
				value={value}
				autoComplete="off"
				onKeyPress={(e) => {
					if (name === 'password' && onEnterFunction) {
						onEnterFunction(e);
					} else {
					}
				}}
				disabled={disable?true:false}
			/>

			{type === 'password' && (
				<>
					<div
						className="password-display"
						onClick={() => setPasswordVisible(!passwordVisible)}
					>
						<img
							src={passwordVisible ? PassNotVisibleIcon : PassVisibleIcon}
							alt="visibleIcon"
						/>
					</div>
				</>
			)}
		</div>
	);
};

export const InputFieldForPassword = ({
	text,
	type,
	placeholder,
	value,
	name,
	handleOnChange,
	fontSize,
}) => {
	const [passwordVisible, setPasswordVisible] = useState(false);
	return (
		<div className="InputField">
			<input
				name={name}
				// type={passwordVisible ? 'text' :type}
				style={{ fontSize: fontSize ? fontSize : '' }}
				placeholder={placeholder}
				onChange={(e) => handleOnChange(e)}
				value={passwordVisible ? value : '*'.repeat(value.length)}
			/>

			{type === 'password' && (
				<>
					<div
						className="password-display"
						onClick={() => setPasswordVisible(!passwordVisible)}
					>
						<img
							src={passwordVisible ? PassNotVisibleIcon : PassVisibleIcon}
							alt="visibleIcon"
						/>
					</div>
				</>
			)}
		</div>
	);
};

export const InputBox = ({
	width,
	label,
	type,
	handleChange,
	value,
	isError,
	outerIndex,
	actualIndex,
	disable,
	notRequired,
	helpText,
	min,
	max,
}) => {
	return (
		<>
			<div className="input-box">
				<Tooltip id="my-tooltip" className="custom-tooltip"/>
				<div className="label">
					{label}
					{notRequired ? '' : <span className="required">*</span>}
					{helpText && 
							<div
								data-tooltip-id="my-tooltip"
								data-tooltip-content={helpText}
							>
							<InfoIcon />
						</div>
					}
				</div>
				<div className="input-field">
					<input
						disabled={disable}
						style={{
							border: isError ? '1px solid #FF2626' : '',
							width: width ? width : '100%',
							padding: type === 'date' ? '11px 20px' : '13px 20px',
						}}
						onChange={(e) => {
							handleChange(label, e, type, outerIndex, actualIndex);
						}}
						type={type}
						value={value}
						min={min}
						max={max}
						required
					/>
				</div>
			</div>
		</>
	);
};

export const SearchInputBox = ({
	width,
	label,
	type,
	handleChange,
	value,
	isError,
	list,
	isLoading,
	onSelect,
	disable=false,
}) => {
	const [opendialog, setOpenDialog] = useState(false);
	const dialogRef1 = useRef();
	useOnClickOutside(dialogRef1, () => {
		setOpenDialog(false);
	});
	return (
		<>
			<div className="input-box">
				<div className="label">
					{label}
					<span className="required">*</span>
				</div>
				<div
					className="input-field"
					style={{ position: 'relative' }}
					ref={dialogRef1}
				>
					<input
						style={{
							border: isError ? '1px solid #FF2626' : '',
							width: width ? width : '100%',
							padding: type === 'date' ? '11px 20px' : '13px 20px',
						}}
						onChange={(e) => {
							if (!disable) {
								handleChange(label, e, type);
								setOpenDialog(true);  
							}
						}}
						type={type}
						value={value}
						required
					/>
					{value !== '' && opendialog && (
						<>
							<div className="dialog-container">
								{isLoading ? (
									<Loader height="32px" width="32px" />
								) : (
									<>
										{list?.length === 0 ? (
											<>
												<div
													className="list-item"
													style={{ textAlign: 'center', paddingTop: '15px' }}
												>
													No facilitator available
												</div>
											</>
										) : (
											<>
												{list?.map((item, index3) => {
													return (
														<React.Fragment key={index3}>
															<div
																className="list-item"
																onClick={() => {
																	setOpenDialog(false);
																	onSelect(label, item);
																}}
															>
																{item.facilitator}
															</div>
														</React.Fragment>
													);
												})}
											</>
										)}
									</>
								)}
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export const InputBoxWithDropdown = ({
	width,
	label,
	list,
	value,
	onSelect,
	isError,
	placeholder,
	isCompany,
	isForm,
	outerIndex,
	actualIndex,
	disable,
	notRequired,
	helpText
}) => {
	const [opendialog, setOpenDialog] = useState(false);
	const dialogRef = useRef();
	useOnClickOutside(dialogRef, () => setOpenDialog(false));
	return (
		<>
			<div className="input-box"
			style={{
				width: width ? width : '100%',
			}}
			>
				<div className="label">
					{label}
					{notRequired?'':<span className="required">*</span>}
					{helpText && 
							<div
								data-tooltip-id="my-tooltip"
								data-tooltip-content={helpText}
							>
							<InfoIcon />
						</div>
					}

				</div>
				<div
					className="main-div"
					ref={dialogRef}
					onClick={() => { 
						if (!disable) setOpenDialog(!opendialog);
					}}
				>
					<div
						style={{
							// width: width ? width : '100%',
							border: isError ? '1px solid #FF2626' : '',
							background: 'white',
						}}
						className="innerDiv"
					>
						{value ? value : placeholder ? list[0].name : 'Select a value'}
					</div>
					<div className="dropdown-icon">
						<img
							src={DropdownIcon}
							alt=""
							className="arrow_img"
							style={{
								transform: opendialog ? 'rotate(180deg)' : 'rotate(360deg)',
							}}
						/>
					</div>

					{opendialog && (
						<>
							<div className="dialog-container">
								{list?.map((item, index3) => {
									return (
										<React.Fragment key={index3}>
											<div
												className="list-item"
												onClick={() => {
													setOpenDialog(false);
													if (isCompany) {
														onSelect(label, item.company_name, item);
													} else if (isForm) {
														onSelect(
															'dropdown',
															item.name,
															outerIndex,
															actualIndex
														);
													} else {
														onSelect(label, item.name);
													}
												}}
											>
												{isCompany ? item.company_name : item.name}
											</div>
										</React.Fragment>
									);
								})}
							</div>
						</>
					)}
				</div>
			</div>
		</>
	);
};

export const InputTextArea = ({
	label,
	type,
	height,
	placeholder,
	value,
	handleChange,
	isError,
	disable,
}) => {
	return (
		<>
			<div className="input-box">
				<div className="label">
					{label}
					<span className="required">*</span>
				</div>
				<div className="textarea-container">
					<textarea
						disabled={disable}
						style={{
							minHeight: height ? height : '52px',
							border: isError ? '1px solid #FF2626' : '',
						}}
						placeholder={placeholder ? placeholder : ''}
						value={value}
						onChange={(e) => handleChange(label, e, type)}
					/>
				</div>
			</div>
		</>
	);
};


export const InputAutoAddress = ({
	label,
	type,
	height,
	placeholder,
	value,
	isError,
	handleValueChange,
	handlePlaceChanged,
	inputRef,
	notRequired,
	isLoaded
}) => {
	return (
		<>
			<div className="input-box">
				<div className="label">
					{label}
					{notRequired?'':<span className="required">*</span>}
				</div>
				<div className="input-field">
					{isLoaded && (
						<StandaloneSearchBox
							onLoad={(ref) => (inputRef.current = ref)}
							onPlacesChanged={handlePlaceChanged}
							className="input-field"
						>
							<input
								type="text"
								className="form-control"
								placeholder="Enter Location"
								value={value}
								onChange = {(e)=>handleValueChange(label,e.target.value)}
								style={{
									padding: '12px 20px',
									outline: 'none !important',
									border: '1px #e6e6fc solid',
								}}
							/>
						</StandaloneSearchBox>
					)}
				</div>
			</div>
		</>
	);
};