import { get_method, post_method, delete_method } from "../utils.js";

export const getFacilitatorPortfolioList = async (project_id, search_text = null) => {
  const url = `/facilitator_portfolio?project_id=${project_id}&query=${search_text}`;
  return await get_method(url);
};

export const getFacilitatorList = async (text, id) => {
  const url = `facilitator_list?project_id=${id}&query=${text}`;
  return await get_method(url);
};

export const getFacilitatorListAll = async (id) => {
  const url = `/project_facilitator_list?project_id=${id}`;
  return await get_method(url);
};

export const fetchCompanyList = async () => {
  const url = `/facilitator_company_list`;
  return await get_method(url);
};

export const addUser = async (data) => {
  const url = `/create_users`;
  return await post_method(url, data);
};

export const createFacilitator = async (data) => {
  const url = `/create_facilitator`;
  return await post_method(url, data);
};

export const checkEmailExist = async (data) => {
  const url = `/check_email`;
  return await post_method(url, data);
};

export const sendReminder = async (data) => {
  const url = `/send_reminder`;
  return await post_method(url, data);
};

export const deleteFacilitator = async (id) => {
  const url = `/delete_facilitator`;
  return await delete_method(url, id);
};

export const togglePaidToFarmer = async (id) => {
  const url = `/toggle_paid_to_farmer`;
  return await post_method(url, id);
}