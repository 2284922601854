import React, { useEffect, useRef, useState, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import {BrokerViewSavePreAssigned} from '../../api/broker_view/endpoints';
import "./CreditsTable.css";
import { toastFailure, toastSuccess } from '../../Components/Toaster/Toast';

import { Button } from "@mui/material";


// Mock API call to simulate data persistence
const makeUpdateAPI = async (row) => {
    if (row.preassigned_credits <= row.available_to_preassign) {
        let data = {
            project_id: parseInt(localStorage.getItem('selectedProject')),
            milk_sold_to: row.milk_sold_to,
            city: row.city,
            state: row.state,
            preassigned_credits: parseFloat(row.preassigned_credits),
            notes: row.notes,
        }
        const res = await BrokerViewSavePreAssigned(data);
        if (res.status === 200) {
            return "Success";
        } else {
            throw new Error("Saving call failed");
        }
    };
};

const CreditsTable = (props) => {
    const [rows, setRows] = useState(props.creditsTableData);

    // Handle row update
    const handleSave = async (newRow, oldRow) => {
        console.log('HERE....', newRow.preassigned_credits, oldRow.preassigned_credits)
        // Validate the new value
        if (newRow.preassigned_credits > oldRow.available_to_preassign) {
            toastFailure("Value exceeds available to preassign.");
            setRows((prevRows) =>
                prevRows.map((row) => (row.id === newRow.id ? oldRow : row))
            );   
        } else {
            try {
                let result = await makeUpdateAPI(newRow);

                // Recalculate available_to_preassign
                if (result === "Success") {
                    const updatedRow = {
                        ...newRow,
                        available_to_preassign: oldRow.available_to_preassign - (newRow.preassigned_credits - oldRow.preassigned_credits),
                    };

                    // Update rows state
                    setRows((prevRows) =>
                        prevRows.map((row) => (row.id === newRow.id ? updatedRow : row))
                    );
                    toastSuccess("Successfully updated.");
                    return updatedRow;
                } else {

                }
            } catch (error) {
                console.log('API ERROR.')
                setRows((prevRows) =>
                    prevRows.map((row) => (row.id === newRow.id ? oldRow : row))
                );
                toastFailure("API update failed.");
            }
        }
    };

    const columns = [
        { field: 'milk_sold_to', headerName: 'Milk Sold To', flex: 1.5},
        { field: 'city', headerName: 'City', flex: 1,},
        { field: 'state', headerName: 'State', flex: 1},
        {
            field: 'credits',
            headerName: 'Credits Available',
            // width: 150,
            flex: 1,
            valueFormatter: (params) => {
                return params.toLocaleString()    
            }, // Format with commas
        },
        { field: 'preassigned_credits', headerName: 'Pre Assigned Credits', flex: 1,
            valueFormatter: (params) => {
                return params.toLocaleString()    
            },
            editable: true,  // Make this column editable
            cellClassName: "editable-cell"
        },
        { field: 'preassigned_credits_by_others', headerName: 'Pre Assigned By Others', flex: 1,
            valueFormatter: (params) => {
                return params.toLocaleString()
            }
        },
        { field: 'available_to_preassign', headerName: 'Available to Pre Assign', flex: 1,
            valueFormatter: (params) => {
                return params.toLocaleString()
            }
        },
        { field: 'notes', headerName: 'Notes', flex: 2.5,
            editable: true,  // Make this column editable
            cellClassName: "editable-cell", // Add a custom class
        },
        {
            field: 'save',
            headerName: 'Save',
            flex: 0.7,
            sortable: false,
            filterable: false,
            renderCell: (params) => {
                let isChanged = false;
                const oldRow = rows.find((row) => row.id === params.row.id);
                const newRow = params.row
                // console.log('OLD', typeof(oldRow.notes), newRow.notes)
                if ((newRow.preassigned_credits !== oldRow.preassigned_credits)||(oldRow.notes !== '' && newRow.notes !== '' && (newRow.notes !== oldRow.notes))) {
                    isChanged = true;
                }

                return (
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleSave(params.row, oldRow)}
                    disabled={isChanged}
                >
                    Save
                </Button>
            )},
        }

    ];


    return props.isLoading ? (<></>) : (
        <div>
            <Box sx={{ 
                height: 400, 
                width: '100%',
            }}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    initialState={{
                        sorting: {
                            sortModel: [
                                { field: "available_to_preassign", sort: "desc" }, // Default sort by 'credits' in ascending order
                            ],
                        },
                    }}
                    editMode="row" // Highlights the row being edited
                    // processRowUpdate={processRowUpdate} // Handle updates
                    // onProcessRowUpdateError = {handleProcessRowUpdateError}
                />
            </Box>            
        </div>
    )
}

export default CreditsTable;