import React from 'react'
import './FAQs.css'
import LogoNew from '../../images/Auth/SVG 1.svg';
import { useParams } from 'react-router-dom';


const faqs_for_farmers = {
    1: {
        section_title: 'Questions regarding the Farm Form',
        content: [
            {
                question: 'What is the primary evidence I need to upload? Where do I find examples?',
                answer: 'For details on primary evidence, please contact your facilitator representative. They will be able to provide you with examples and provide materials to guide you.'
            },
            {
                question: 'How do I find accurate cow numbers on my farm?',
                answer: 'For details on how to find and submit accurate cow numbers from your farm, please contact your facilitator representative. They will be able to provide you with examples and provide materials to guide you.'
            },
            {
                question: 'What if I have multiple nutritionists?',
                answer: 'Please only enter your most recent nutritionist. This nutritionist or yourself should be able to collect historical data from any prior nutrition group as needed. Your current nutritionist can help you determine what to ask for.'
            },
            {
                question: 'Who can see my data?',
                answer: 'The facilitator representative, lead facilitator, and the auditor can see your data. Facilitators are the personnel who are supporting your entry into the project.'
            },
            {
                question: 'What is the farm data entry period?',
                answer: 'Data entry starts at the beginning of the calendar year until the end of January for the previous calendar year data entry. January 1st through January 31st each year.'
            },
            {
                question: 'What should I do if I cannot find the link to the farm form?',
                answer: 'Please reach out to your facilitator representative. They will be able to send you an email with the farm data form link and the passcode to enter the form.'
            },
            {
                question: 'Will I be notified if my farm was approved by the facilitator?',
                answer: 'Yes, upon successful data entry and approval, you should receive an email from support@carboncredify.ai notifying that the farm form was approved.'
            },
            {
                question: 'What should I do if I receive "Facilitator has requested changes on your farm form" email?',
                answer: 'After you submit the form, the facilitator representative can request modifications from you during validation. The email should contain the information that the facilitator is asking for. In case of further clarification, please work with your facilitator representative.'
            }
        ]
    },
    2: {
        section_title: 'Questions about Technical Support',
        content: [
            {
                question: 'What if I don’t have internet access for data entry?',
                answer: 'Currently, to participate in the project, you will need internet connectivity to access the farm form, save, and submit it.'
            },
            {
                question: 'What do I do if I’m having technical issues?',
                answer: 'Check with your facilitator representative on the issue. If the issue still persists, then reach out to support@carboncredify.ai with your query, or have your facilitator representative reach out to the Carboncredify support team via the contact us form.'
            },
            {
                question: 'How long until I should expect a response from the Carboncredify team?',
                answer: 'Typically, you should expect a response within 24 hours of your query submission.'
            }
        ]
    },
    3: {
        section_title: 'Questions about project participation',
        content: [
            {
                question: 'What do I need to do to exit the project? What happens to my data in that case?',
                answer: 'To exit the project, please inform your facilitator representative and have them reach out to the support team. Your data will be retained in the Carboncredify system for the next 7 years for audit purposes.'
            },
            {
                question: 'When will I receive payment from the project?',
                answer: 'Credits are generated and sold once annually. Data is submitted in January, representing the prior calendar year’s practice of feeding the feed additive. Verification of the data happens within the same year data is submitted, and farms are paid after passing verification. The timeline of payment may vary depending on the verification and broker process, but the target timeline is Q2 each year. The payment amount will be 85% of the credit value, which has a set floor price of 25 USD. Please refer to your aggregation agreements for more accurate details.'
            }
        ]
    }
};

const faqs_for_facilitators = {
    1: {
        section_title: 'Questions regarding Farm Form',
        content: [
            {
                question: 'What is the primary evidence the farmer needs to upload? Where do I find examples?',
                answer: 'https://docs.google.com/document/d/16WLRt_I7xc7aIcJUMOYAvQ9oT-XBzmx4P3agG4T0HNE/edit?tab=t.0'
            },
            {
                question: 'How do the farmers find accurate cow numbers on the farm?',
                answer: 'https://docs.google.com/document/d/16WLRt_I7xc7aIcJUMOYAvQ9oT-XBzmx4P3agG4T0HNE/edit?tab=t.0'
            },
            {
                question: 'Who can see the farm data?',
                answer: 'Farmer, facilitator representative, lead facilitator, and the auditor can see the farm data.'
            },
            {
                question: 'What is the farm data entry period?',
                answer: 'Data entry starts January 1st till January 31st each year.'
            },
            {
                question: 'What should I do if I can not find the link to the farm form?',
                answer: 'You can retrieve the link of the farm form from the Carboncredify portal on the "Farm Portfolio" page.'
            },
            {
                question: 'Will I be notified if the farm was submitted by the farmer?',
                answer: 'Yes, upon successful data entry and submission, you should receive an email from support@carboncredify.ai notifying that the farm form was submitted and is ready for your review. You can track this through the farm portfolio on the Carboncredify portal. Upon submission, the farm status on the portal will change to "facilitator pending".'
            },
            {
                question: 'What am I supposed to validate before approving the farm form?',
                answer: 'Before submitting the farm form, you are supposed to verify the uploaded primary documentation, the Agolin™ purchase amount from the uploaded receipts, and the other data entered in the form by the farmer. For you to successfully submit the farm form, the Agolin™ purchase amount should be less than or equal to the Agolin™ fed amount (calculated in the backend using farmer data entry).'
            },
            {
                question: 'What happens after I approve the farm form?',
                answer: 'After approving the farm form, a notification will be sent to the facilitator lead to validate if the receipt data is accurately captured in the farm form. Additionally, notification is sent to the farmer and aggregators that the farm was successfully approved. On the Carboncredify portal, the farm status will change to "Approved".'
            },
            {
                question: 'What should I do if I approve the farm by mistake?',
                answer: 'Please reach out to the Carboncredify team using the contact us form as soon as possible for the farm to be unlocked. Expect an answer within 24 hours of the query submission.'
            }
        ]
    },
    2: {
        section_title: 'Questions about technical support',
        content: [
            {
                question: 'What if I don’t have internet access for data entry?',
                answer: 'At this point, you will need internet connectivity to be able to access the farm form, save, and submit it. Alternatively, you can take notes and enter them into the portal once you have internet.'
            },
            {
                question: 'What do I do if I’m having technical issues?',
                answer: 'Please reach out to the Carboncredify team using the contact us form with your query. Expect an answer within 24 hours of the query submission. The contact us form is available on the Carboncredify portal as an option on the left menu.'
            },
            {
                question: 'How long until I should expect a response from the Carboncredify team?',
                answer: 'Typically, you should expect a response within 24 hours of your query submission.'
            }
        ]
    },
    3: {
        section_title: 'Questions about project participation',
        content: [
            {
                question: 'What should I do if the farm owner needs to exit the project? What happens to the farm data in that case?',
                answer: 'As the farm owner reaches out to you to leave the project, please reach out to the Carboncredify team using the contact us form. The farm data will be retained in the Carboncredify system for the next 7 years for audit purposes.'
            },
            {
                question: 'When should the farm owner expect to receive payment from the project?',
                answer: 'Credits are generated and sold once annually. Data is submitted in January, representing the prior calendar year’s practice of feeding the feed additive. Verification of the data happens within the same year data is submitted, and farms are paid after passing verification. The timeline of payment may vary depending on the verification and broker process, but the target timeline is Q2 each year. The payment amount will be 85% of the credit value, which has a set floor price of 25 USD. Please refer to your aggregation agreements for more accurate details.'
            }
        ]
    }
};

const FAQs = () => {
    const { category } = useParams();
    let faqs = category === 'farmers' ? faqs_for_farmers : faqs_for_facilitators;

    return (
        <div className='faqs-container'>
			<div>
				<img src={LogoNew} alt="Logo" />
			</div>
            <br />

            <h1>Frequently Asked Questions (FAQs)</h1>
            <br />
            <div className='faq-section'>
                {Object.keys(faqs).map((section, index) => (
                    <div key={index}>
                        <h2>{faqs[section].section_title}:</h2>
                        <br />
                        <div>
                            {faqs[section].content.map((content, index) => (
                                <div key={index}>
                                    <h5>{index+1}) {content.question}</h5>
                                    {content.answer.startsWith('https') ? (
                                    <p style={{paddingLeft: '25px'}}>
                                        <a href={content.answer} target="_blank" rel="noopener noreferrer">
                                            {content.answer}
                                        </a>
                                    </p>
                                    ) : (
                                        <p style={{paddingLeft: '25px'}}>{content.answer}</p>
                                    )}
                                <br />
                                </div>
                            ))}
                        </div>
                        <br />
                        <br />
                    </div>
                ))}
        </div>
        </div>
    )
}

export default FAQs